import React, { ReactElement } from "react";

export default function Lawsuit(): ReactElement {
    return (
        <section className="relative my-10">
            <div className="py-10 md:p-16 flex justify-center items-center flex-col">
                <h2
                    style={{ color: "#272361" }}
                    className="text-center font-semibold text-xl md:text-3xl md:w-3/4"
                >
                    What Is the Camp Lejeune Lawsuit?
                </h2>
                <p
                    style={{ color: "#444b53" }}
                    className="mt-6 text-justify md:text-lg w-7/8 md:text-center font-light"
                >
                    The Camp Lejeune lawsuit aims to give money to people who
                    lived or worked at the Marine Corps base in North Carolina
                    between 1953 and 1987 and were exposed to toxic water. The
                    contamination affected over a million people and resulted in
                    illnesses such as cancer and birth defects. The lawsuit
                    wants to hold the government accountable for not doing
                    enough and compensate those who have been hurt.
                </p>
            </div>
        </section>
    );
}
